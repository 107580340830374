html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.main {
  background-color: aliceblue;
}

.header {
  display: flex;
  height: 125px;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  border-bottom: 10px solid #10599E;
  ;
}

.logo {
  height: 100px;
  width: 250px;
  margin-left: 50px;
  margin-right: 50px;
  padding: 0 10px;
}

.nav {
  display: inline-block;
  list-style: none;
  margin-right: 45px;
  color: #10599e;
  font-weight: bold;
  font-size: 22px;
}

.navlink:link {
  text-decoration: none;
  color: #10599e;
}

.navlink:hover {
  color: #007EA7;
  transition: color 0.1s linear;
}

.navlink:visited {
  color: #10599e;
  text-decoration: none;
}

#cont-home {
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-bottom: 10px solid #10599E;
}

.cont {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-bottom: 10px solid #10599E;
}

.school {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 20%;
  position: absolute;
}

#overlay-home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 650px;
  background: linear-gradient(0deg, rgb(0, 0, 0, 0.9), rgb(0, 0, 0, 0.25));
  top: 135px;
  color: white;
  top: 0;
}

.overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 250px;
  background: linear-gradient(0deg, rgb(0, 0, 0, 0.9), rgb(0, 0, 0, 0.25));
  top: 135px;
  color: white;
  top: 0;
}

h1 {
  font-size: 96px;
  margin: 0 35px;
  text-shadow: 6px 6px 6px rgb(40, 44, 52, 0.75);
}

.subtitle {
  font-size: 48px;
  font-weight: lighter;
  color: white;
  text-shadow: 6px 6px 6px rgb(40, 44, 52, 0.75);
}

hr {
 margin: 50px 100px;
 background-color: #282c34;
 border: 2px solid #282c34;
}

.welcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:rgb(85, 85, 85, 0.25);
  margin-top: 50px;
}

.welcome-post {
  font-size: 28px;
  color:#282c34;
  background: linear-gradient(150deg, #007EA7, #7A16C1);
  margin-top: 50px;
  padding: 25px 0;
}

h2 {
  text-align: center;
  font-size: 72px;
  color: white;
  padding: 30px;
  margin: 0;
}

p {
  margin: 50px 100px;
  font-size: 24px;
  color: #282c34;
}

.desc {
  padding: 50px 100px;
  margin: 0;
  font-size: 24px;
  color: white;
}

h3 {
  font-weight: bold;
  font-size: 40px;
  color: #007EA7;
  margin: 50px 25px;
}

h4 {
  font-size: 24px;
  margin: 0 25px;
}

.announcement {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.announcement > img {
  width: 30%;
  max-width: 1000px;
  min-width: 10%;
}

.announcement > p {
  font-weight: 600;
}

.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
}

.gallery-cont {
  margin: 25px;
  width: 30%;
  min-width: 300px;
  max-width: 700px;
  height: 500px;
}

.gallery-img {
  width: 100%;
  height: 100%;
  box-shadow: 10px 10px #282c34;
  object-fit: cover;
}

p.gallery-caption {
  padding-top: 50px;
  margin: 0;
}

#empty {
  height: 100px;
}

#main-resources {
  background-color: aliceblue;
}


.links {
  padding: 50px 0;
}

#links-cont {
  width: 85%;
  min-height:400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.resource-link {
  text-decoration: none;
}

.link { 
  background-color: #10599e;
  border: 10px solid #0a355f;
  box-shadow: #0a355f 2px 2px 4px;
  width: 25%;
  height: 200px;
  min-width: 350px;
  max-width: 350px;
  margin: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.link:hover {
  background-color: #10599ec5;
}

.link:active {
  background-color: #10599ec5;
}

.last-link {
  width: 25%;
  min-width: 350px;
  max-width: 350px;
  margin: 35px;
  margin-top: 0;
  margin-bottom: 0;
}

.link > p {
  font-size: 42px;
  margin: 0;
  font-weight: bolder;
  color: white;
  padding: 20px;
  cursor: pointer;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s;
}

.fade-in.show {
  opacity: 1;
}

#main-calendar {
  background-color: aliceblue;
}

#calendar {
  margin: 75px auto;
  border: 25px solid rgba(16, 89, 158, 1);
  width: 90%;
  box-shadow: #0a355f 2px 2px 4px;
  padding-top: 50%;
  position: relative;
}

#calendar-link {
  margin: 200px auto;
}

#calendar > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#contact-info {
  display: flex;
  background: linear-gradient(150deg, #007EA7, #7A16C1);
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px auto;
  width: 95%;
  border-radius: 125px;
  padding: 0;
}

.contact {
  color: white;
  font-size: 150px;
}

.contact:hover {
  transform: scale(1.01);
  text-shadow: #0a355f 2px 2px 10px;
  transition: 0.1s linear;
}

#email, #remind, #instagram {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 45px;
}

#contact-info > div > p {
  font-size: 48px;
  color: white;
  margin: 25px;
  margin-right: 0;
  max-width: 300px;
}

#form {
  width: 75%;
  height: 50%;
  min-height: 700px;
  background-color: rgb(16, 89, 158, 0.1);
  border: 25px solid #10599E;
  box-shadow: #0a355f 2px 2px 4px;
  margin-bottom: 50px;
}

#orange {
  background-color: #F08130;
  height: 25px;
  
}

#celadon {
  background-color: #007EA7;
  height: 25px;
}

#blue {
  background-color: #10599E;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#blue > a > i {
  color: white;
  font-size: 32px;
}

#blue > a > i:hover {
  text-shadow: #0a355f 2px 2px 10px;
  transition: 0.1s linear;
}

#noscript {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 708px) {
  .spacer {
    width: 42px;
    margin: 0 50px;
  }

  .header {
    border-bottom: 0;
  }
  
  .logo {
    margin: 0;
  }

  h1 {
    font-size: 60px;
    margin: 0 25px;
  }

  .subtitle {
    font-size: 36px;
    margin: 50px 50px;
  }

  .header {
    justify-content: space-between;
  }

  .fa-bars {
    font-size: 48px;
    margin: 0 50px;
    color: #282c34;
  }

  .fa-bars:hover {
    color: #282c34b0;
    transition: color 0.15s linear;
  }

  ul {
    margin: 0;
    padding: 0;
    
  }

  .nav-mobile {
    display: block;
    margin: 0 auto;
    font-size: 26px;
    padding: 20px;
    background: #282c3428;
    color: #282c34be;
    font-weight: 700;
  }

  .nav-mobile:hover {
    background: linear-gradient(rgba(40, 44, 52, 0.233), #282c3456);
  }

  .nav-mobile:active {
    background: linear-gradient(rgba(40, 44, 52, 0.233), #282c3456);
  }

  #overlay-home, .overlay {
    border-top: 10px solid #10599e;
  }

  h2 {
    font-size: 48px;
  }

  #contact-info {
    border-radius: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .spacer {
    width: 0;
    margin: 0;
  }

  p {
    padding: 15px !important;
    margin: 15px !important;
  }

  iframe, #calendar {
    border: none !important;
    box-shadow: none !important;
    width: 100% !important;
  }

  #contact-info > div > p {
    font-size: 0;
    content: none;
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 400px) {
  .header {
    flex-direction: column;
    height: 250px;
    justify-content: center;
  }

  .fa-bars {
    font-size: 72px;;
  }

  h1 {
    font-size: 48px;
  }

  #links-cont {
    display: block !important;
    margin: 0 auto !important;
  }

  #links-cont > a {
    width: 100%;
    margin: 10px;
  }

  .link {
    min-width: 270px !important;
    margin: 0 auto;
  }
}